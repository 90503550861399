export const environment = {
  server: 'https://api-pix.mercattum.com',
  app: 'https://mercattum-admin.web.app/',
  production: true,
  banking: {
    name: 'Mercattum',
    email: 'contato@mercattum.com',
    site: 'https://mercattum.com',
    termsOfUse: 'https://mercattum.com/termos-de-uso',
  }
};
